import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { useCommodityStore } from '../../services/store';

type CommodityModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<CommodityModelState>({
  headers: [
    {
      text: 'Nama Komoditas',
      value: 'name'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useCommodityModel() {
  const store = useCommodityStore();

  const onGetManyCommodity = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyCommodity();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateCommodity = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postCommodity(data);
      useUiStateStore().createSnackbar({
        message: `Komoditas ${data.name} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyCommodity();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateCommodity = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putCommodity(data);
      useUiStateStore().createSnackbar({
        message: `Komoditas ${data.name} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyCommodity();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createCommodity = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editCommodity = (commodity: any) => {
    state.formData = { ...commodity };
    state.showFormDialog = true;
  };

  const deleteCommodity = (commodity: any) => {
    state.formData = commodity;
    state.showDeleteDialog = true;
  };

  const onDeleteCommodity = async () => {
    state.isLoading = true;

    try {
      await store.deleteCommodity(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `Komoditas ${data.name} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyCommodity();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateCommodity(state.formData);
    else onCreateCommodity(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyCommodity,
    onSubmitForm,
    onDeleteCommodity,
    deleteCommodity,
    editCommodity,
    createCommodity
  };
}

export { useCommodityModel };
